// @flow strict
import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Sidebar from '../components/Sidebar'
import Feed from '../components/Feed'
import Page from '../components/Page'
import Pagination from '../components/Pagination'
import { useSiteMetadata } from '../hooks'
import { FaTwitter, FaFacebook, FaEnvelope, FaLinkedin } from 'react-icons/fa'
import { ShareButtonIconOnly, ShareBlockStandard } from 'react-custom-share'
import type { PageContext, AllMarkdownRemark } from '../types'

type Props = {
  data: AllMarkdownRemark,
  pageContext: PageContext
}

const IndexTemplate = ({ data, pageContext }: Props) => {
  const { title: siteTitle, subtitle: siteSubtitle } = useSiteMetadata()

  const {
    currentPage,
    hasNextPage,
    hasPrevPage,
    prevPagePath,
    nextPagePath
  } = pageContext

  const { edges } = data.allMarkdownRemark
  const pageTitle = currentPage > 0 ? `Posts - Page ${currentPage} - ${siteTitle}` : siteTitle

  const shareBlockProps = {
    url: 'https://bendurham.dev',
    button: ShareButtonIconOnly,
    buttons: [
      { network: 'Twitter', icon: FaTwitter },
      { network: 'Facebook', icon: FaFacebook },
      { network: 'Email', icon: FaEnvelope },
      { network: 'Linkedin', icon: FaLinkedin }
    ],
    text: `Software development tutorials and more - bendurham.dev `,
    longtext: `Solve your technical problems now.`
  }

  return (
    <Layout title={pageTitle} description={siteSubtitle}>
      <Sidebar isIndex />
      <Page>
        {/* <ShareBlockStandard {...shareBlockProps} /> */}
        <Feed edges={edges} />
        <Pagination
          prevPagePath={prevPagePath}
          nextPagePath={nextPagePath}
          hasPrevPage={hasPrevPage}
          hasNextPage={hasNextPage}
        />
      </Page>
    </Layout>
  )
}

export const query = graphql`
  query IndexTemplate($postsLimit: Int!, $postsOffset: Int!) {
    allMarkdownRemark(
        limit: $postsLimit,
        skip: $postsOffset,
        filter: { frontmatter: { template: { eq: "post" }, draft: { ne: true } } },
        sort: { order: DESC, fields: [frontmatter___date] }
      ){
      edges {
        node {
          fields {
            slug
            categorySlug
          }
          frontmatter {
            title
            date
            category
            description
          }
        }
      }
    }
  }
`

export default IndexTemplate
